<template>
  <div>
    <v-row align="center">
      <v-col cols="3">
        <v-btn block height="42px" color="#1db954" style="color: black; margin-top: 10px" class="download-btn"
          to="/app/pages/management/user-list/CreateUser">
          <v-icon>mdi mdi-plus-thick</v-icon>
          Neu
        </v-btn>
      </v-col>
      <v-col cols="3" v-if="!hideFilters" class="custom-switch-col">
        <v-row align="center">
          <v-switch inset v-model="showOwners" @change="fetchUserss"></v-switch>
          <span class="ml-2">{{
            showOwners ? "Besitzer*in anzeigen" : "Besitzer*in ausblenden"
          }}</span>
        </v-row>
      </v-col>
      <v-col cols="3" v-if="!hideFilters" class="custom-switch-col">
        <v-row align="center">
          <v-switch inset v-model="isActive" @change="fetchNotActiveUsers"></v-switch>
          <span class="ml-2">{{ isActive ? "Aktiv" : "Archiviert" }}</span>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table :headers="headers" :search="testVal" :items="formatUsers" :loading="isLoading" :footer-props="{
      'items-per-page-text': 'Zeilen pro Seite',
    }">
      <template v-slot:item.userTypeId="{ item }">
        {{ getRoleString(item.userTypeId) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="item.isActive" small class="green--text icon-style"
          @click="editUser(item)">mdi-pencil-outline</v-icon>
        <v-icon v-if="!item.isActive" small class="green--text icon-style"
          @click="unlockUser(item)">mdi-lock-open-outline</v-icon>
      </template>
    </v-data-table>
    <UpdateUser :user="selectedUser" :itemList="userList" v-if="selectedUser.id"></UpdateUser>
  </div>
</template>

<script>
import axios from "axios";
import CreateUser from "./CreateUser";
import UpdateUser from "./UpdateUser";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "user-list",
  components: {
    CreateUser,
    UpdateUser,
  },
  data() {
    return {
      headers: [
        { text: "Vorname", value: "firstName" },
        { text: "Name", value: "lastName" },
        { text: "Initialen", value: "initials" },
        { text: "Rolle", value: "userTypeId", align: "start" },
        { text: "", value: "actions", sortable: false },
      ],
      showOwners: false,
      isActive: true,
      hideFilters: false,
      itemList: [],
      userUpdateKey: null,
      isLoading: false,
      dialogType: "",
      selectedUser: {},
      localSearch: null,
    };
  },
  computed: {
    ...mapGetters(["getSearchquery", "coverages", "users", "isOwner","getEditedUser"]),
    testVal() {
      return this.getSearchquery;
    },
    formatUsers(){
      let filteredUsers = [];
      if(this.getEditedUser !== null) {
        const filteredUsersTable = [...this.users]
        const editedUserIndex = this.users.findIndex(item => item.id == this.getEditedUser);
        const editedItem = filteredUsersTable.splice(editedUserIndex, 1)[0];
        filteredUsersTable.unshift(editedItem);
        filteredUsers = filteredUsersTable
        return filteredUsers
      } else {

        return this.users
      }
    }
  },

  mounted() {
    this.localSearch = this.getSearchquery;
    this.showOwners = this.isOwner
    this.fetchData();
  },
  created() {
  },
  methods: {
    checkIsOwner(){
      console.log("hyni", this.isOwner);
      this.fetchUsers({isOwners: this.isOwner})
    },
    async fetchUserss() {
      this.editingUser(null)
      this.fetchUsers({ isOwners: this.showOwners });
    },

    async fetchNotActiveUsers() {
      this.fetchUsers({ isOwners: this.showOwners, isActive: this.isActive });
    },

    ...mapActions(["fetchUsers", "createUser", "updateUser","editingUser"]),
    editUser(user) {
      this.editingUser(user.id)
      console.log("user",user);
      this.selectedUser = user;
      this.$router.push({
        name: "Bearbeiten benutzer",
        params: { id: user.id },
        props: {
          user: this.selectedUser,
        },
      });
    },
    async unlockUser(user) {
      const response = await axios.post(
        `https://tilia.rrota.org/api/User/ActivateUserViaAdmin/${user.id}`
      );

      this.fetchUsers({ isOwners: this.showOwners, isActive: this.isActive });
    },
    viewUser(user) {
      this.selectedUser = user;
      this.$router.push({
        name: "Bearbeiten info",
        params: { id: user.id },
        props: {
          user: this.selectedUser,
        },
      });
    },
    async addItem(newItem) {
      this.isLoading = true;
      await this.createUser(newItem);
      this.$store.commit("addUser", newItem);
      this.fetchUsers({ isOwners: this.showOwners });
    },
    async updateItem(item) {
      this.isLoading = true;
      await this.updateUser(item);
      this.userUpdateKey += 1;
    },
    async fetchData() {
      this.isLoading = true;
      await this.fetchUsers({ isOwners: this.showOwners });

      // this.users.sort((a, b) => {
      //   return a.userTypeId === 1 ? -1 : b.userTypeId === 1 ? 1 : 0;
      // });

      this.isLoading = false;
    },
    getRoleString(userTypeId) {
      const roleMap = {
        1: "Admin",
        2: "Verwaltende*r",
        3: "Ausführende*r",
        4: "Inventarbesitzer*in",
      };

      return roleMap[userTypeId] || "";
    },
    handleSearch(query) {
      this.getSearchquery = query;
    },
  },
};
</script>

<style>
.icon-style {
  border: 1px solid #494949;
  padding: 3px;
  border-radius: 5px;
  width: 42px;
  height: 42px;
  margin-left: -60px !important;
}

.download-btn {
  margin-bottom: 23px;
}

.custom-switch-col {
  margin-bottom: 10px;
  /* Adjust the top margin as needed */
  margin-left: 30px;
  font-size: 0.875rem;
}
</style>
